;
@font-face {
    font-family: 'SohneSchmal';
    src: url('../../../webfonts/SohneSchmal.ttf')  format('truetype'), /* Safari, Android, iOS */
}

html, body {
    background: #FFFFFF;
    min-height: 812px;
    height: 100vh;
    margin: 0;
}

.header-title {
    font-family: 'SohneSchmal';
    font-size: 34px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
}

.error-title {
    font-family: 'SohneSchmal';
    font-size: 48px;
    font-weight: 400;
    line-height: 48px;
    text-transform: uppercase;
}

.error-bg {
    background: url('../../../error_bg.webp') no-repeat;
    background-size: contain;
}

.selected {
    font-weight: 700;
    border-bottom: 2px solid #000000;
}

.over-spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 812px;
    height: 100vh;
    background: #FFFFFF;
}

.spinner {
    position: absolute;
    margin: auto;
    top: 45%;
    left: 42%;
    width: 64px;
    height: 64px;
    content: url('../../../spinner.gif');
}

/* styles for react-horizontal-scrolling-menu */
.react-horizontal-scrolling-menu--scroll-container{display:flex;height:max-content;overflow-y:hidden;position:relative;width:100%}.react-horizontal-scrolling-menu--scroll-container.rtl{direction:rtl}.react-horizontal-scrolling-menu--inner-wrapper{display:flex;overflow-y:hidden}.react-horizontal-scrolling-menu--wrapper{display:flex;flex-direction:column;margin-left:8px;}.react-horizontal-scrolling-menu--footer,.react-horizontal-scrolling-menu--header{width:100%}.react-horizontal-scrolling-menu--arrow-left,.react-horizontal-scrolling-menu--arrow-right{display:flex}